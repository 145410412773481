<template>
  <div>
    <Header />

    <div class="working">
      <!-- <div class="working-top breadcrumbs d-flex align-items-center">
        <div
          class="container position-relative d-flex flex-column align-items-center"
        ></div>
      </div> -->
    </div>
    <b-container>
      <b-row>
        <b-col sm="12" md="12">
          <div class="about-block1">
            <div class="container text-center">
              <!-- <h1 class="working-title h1">
                {{ $t("how_working.working_title") }}
              </h1> -->
              <h1 class="working-title h1">
                {{ $t("how_working.working_main") }}
              </h1>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="contact-info mb-4">
        <b-col sm="4">
          <div class="contact-info-block">
            <!-- <i class="fa-solid fa-phone" /> -->
            <img
              src="../../../assets/images/working/work-02.png"
              alt=""
              height="295px"
            />

            <div class="working-data">
              <h3>{{ $t("how_working.working_label1") }}</h3>
              <p>
                {{ $t("how_working.working_p1_1") }} <br />
                {{ $t("how_working.working_p1_2") }} <br />
                {{ $t("how_working.working_p1_3") }}
              </p>
            </div>
          </div>
        </b-col>
        <b-col sm="4">
          <div class="contact-info-block">
            <!-- <i class="fa-solid fa-phone" /> -->
            <img
              src="../../../assets/images/working/work-03.png"
              alt=""
              height="295px"
            />

            <div class="working-data">
              <h3>{{ $t("how_working.working_label2") }}</h3>
              <p>
                {{ $t("how_working.working_p2_1") }} <br />
                {{ $t("how_working.working_p2_2") }} <br />
                {{ $t("how_working.working_p2_3") }}
              </p>
            </div>
          </div>
        </b-col>
        <b-col sm="4">
          <div class="contact-info-block">
            <!-- <i class="fa-solid fa-phone" /> -->
            <img
              src="../../../assets/images/working/work-04.png"
              alt=""
              height="295px"
            />

            <div class="working-data">
              <h3>{{ $t("how_working.working_label3") }}</h3>
              <p class="working-data-paragraph">
                {{ $t("how_working.working_p3_1") }} <br />
                {{ $t("how_working.working_p3_2") }} <br />
                {{ $t("how_working.working_p3_3") }}
              </p>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="contact-info mb-4">
        <b-col sm="4">
          <div class="contact-info-block">
            <!-- <i class="fa-solid fa-phone" /> -->
            <img
              src="../../../assets/images/working/work-05.png"
              alt=""
              height="295px"
            />

            <div class="working-data">
              <h3>{{ $t("how_working.working_label4") }}</h3>
              <p>
                {{ $t("how_working.working_p4_1") }} <br />
                {{ $t("how_working.working_p4_2") }} <br />
                {{ $t("how_working.working_p4_3") }}
              </p>
            </div>
          </div>
        </b-col>
        <b-col sm="4">
          <div class="contact-info-block">
            <!-- <i class="fa-solid fa-phone" /> -->
            <img
              src="../../../assets/images/working/work-06.png"
              alt=""
              height="295px"
            />

            <div class="working-data">
              <h3>{{ $t("how_working.working_label5") }}</h3>
              <p>
                {{ $t("how_working.working_p5_1") }} <br />
                {{ $t("how_working.working_p5_2") }} <br />
                {{ $t("how_working.working_p5_3") }}
              </p>
            </div>
          </div>
        </b-col>
        <b-col sm="4">
          <div class="contact-info-block">
            <!-- <i class="fa-solid fa-phone" /> -->
            <img
              src="../../../assets/images/working/work-07.png"
              alt=""
              height="295px"
            />

            <div class="working-data">
              <h3>{{ $t("how_working.working_label6") }}</h3>
              <p class="working-data-paragraph">
                {{ $t("how_working.working_p6_1") }} <br />
                {{ $t("how_working.working_p6_2") }} <br />
              </p>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="contact-info mb-5">
        <b-col sm="4">
          <div class="contact-info-block">
            <!-- <i class="fa-solid fa-phone" /> -->
            <img
              src="../../../assets/images/working/work-08.png"
              alt=""
              height="295px"
            />

            <div class="working-data">
              <h3>{{ $t("how_working.working_label7") }}</h3>
              <p>
                {{ $t("how_working.working_p7_1") }} <br />
                {{ $t("how_working.working_p7_2") }} <br />
              </p>
            </div>
          </div>
        </b-col>
        <b-col sm="8">
          <div class="contact-info-block text-center">
            <!-- <i class="fa-solid fa-phone" /> -->
            <img
              src="../../../assets/images/logo/ico.png"
              alt=""
              height="105px"
              class="mt-5"
            />

            <div class="working-data text-center">
              <p class="text-center">
                {{ $t("how_working.working_footer_1") }} <br />
                {{ $t("how_working.working_footer_2") }} <br />
                {{ $t("how_working.working_footer_3") }}
              </p>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <Footer />
  </div>
</template>

<script>
import Header from "@/layouts/landpage/HeaderHome.vue";
import Footer from "@/layouts/landpage/Footer.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import {
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BForm,
  BContainer,
  BRow,
  BCol,
  BCard,
  BButton,
  BFormDatepicker,
  BFormTextarea,
  BMedia,
  BImg,
  BCardText,
} from "bootstrap-vue";
import { required, email } from "@validations";

export default {
  components: {
    Header,
    Footer,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BContainer,
    BForm,
    BRow,
    BCard,
    BCol,
    BButton,
    BFormDatepicker,
    BFormTextarea,
    BMedia,
    BImg,
    BCardText,
  },

  data() {
    return {
      name: "",
      email: "",
      message: "",
      showDiv: false,
      currentStep: 0,
      currentImage: "aboutUs.png",
      cards: [
        {
          image: "visibility.png",
          title: "Card 1",
          description: "This is a description for card 1",
          alt: "Visibility Image",
        },
        {
          image: "accesibility.png",
          title: "Card 2",
          description: "This is a description for card 2",
          alt: "Accessibility Image",
        },
        {
          image: "247_support.png",
          title: "Card 3",
          description: "This is a description for card 3",
          alt: "24/7 Support Image",
        },
        {
          image: "security.png",
          title: "Card 4",
          description: "This is a description for card 4",
          alt: "Security Image",
        },
      ],
      steps: [
        {
          image: "register.png",
          title: "Title 1",
        },
        {
          image: "create_profile.png",
          title: "Title 2",
        },
        {
          image: "kyc.png",
          title: "Title 3",
        },
        {
          image: "nft.png",
          title: "Title 4",
        },
        {
          image: "startbiz.png",
          title: "Title 5",
        },
      ],
    };
  },
  methods: {
    changeStep(text) {
      this.currentStep = text;
    },
  },
};
</script>

<style scoped>
.text-lg {
  font-size: 48px;
}
.working {
  /* margin-top: 110px; */
  margin-bottom: 75px;

  /* padding: 40px 40px 128px 40px; */
}
.working-block1-col {
  margin-top: 50px;
}

.working .contact-top {
  height: 290px;
}
.card-body {
  min-height: 0px !important;
}
.working .working-block1-img img {
  height: 300px;
}

.working-title {
  font-size: 40px;
  font-weight: 700;
  color: #366ce3;
  margin-bottom: 30px;
}

.working-data {
  margin-top: 20px;
  padding-left: 10px;
}
.working-data h3 {
  color: #366ce3;
  margin-bottom: 10px;
}
.working-data p {
  font-size: 13px;
  text-align: justify;
}
.working-block1-img {
  margin-bottom: 60px;
}
.working .working-block1-info {
  margin-top: 50px;
  padding-left: 50px;
}
.working .working-block1-info h2 {
  font-size: 25px;
  color: #005692;
}
.working .working-block1-info p {
  font-size: 16px;
  font-weight: 500;
  text-align: justify;
}

@media (max-width: 550px) {
  .working .working-top img {
    width: 330px;
  }
  .working .working-block1-img img {
    display: none;
  }
  .working .working-block1-info p {
    text-align: start;
  }
}

.working-block2 {
  margin-top: 60px;
}

.working-points {
  padding: 20px;
  padding-bottom: 0px;
}
.working-detail {
  padding-top: 10px;
  padding-left: 40px;
  font-size: large;
  /* display: none; */
}
.working-block2 h2 {
  text-align: center;
  font-weight: 500;
  color: #005692;
  margin-bottom: 45px;
}

[dir] .card-img,
[dir] .card-img-top {
  height: 250px;
}
[dir] .card .card-title {
  font-weight: 500;
  cursor: pointer;
}
[dir] .card-text:last-child {
  font-size: 14px;
}

@media (max-width: 550px) {
  .block-cvshot .card {
    position: relative;
    display: flex;
    min-width: 0;
    word-wrap: break-word;
    align-content: flex-start;
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }
  .block-cvshot img {
    margin: 0 7px;
    width: 350px;
  }
}
@media (min-width: 920px) {
  .block-cvshot .card .card-title {
    color: #366ce3;
    font-weight: 600;
    font-size: 22px;
  }
  .block-cvshot .card-text:last-child {
    font-size: 15px;
  }
}
@media (min-width: 920px) {
  [dir="ltr"] .card-body {
    padding: 1.5rem;
    min-height: 350px;
  }
  .cvshots-card .card-body {
    min-height: auto;
    margin: auto;
  }
}
.card-text:last-child {
  font-size: 16px;
  font-weight: 500;
}

.working-top {
  background-image: url(../../../assets/images/info/about-header.jpg);
  padding: 140px 0 60px 0;
  min-height: 60vh;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.breadcrumbs:before {
  content: "";
  background-color: rgba(27, 47, 69, 0.7);
  position: absolute;
  inset: 0;
}
.breadcrumbs h2 {
  font-size: 56px;
  font-weight: 500;
  color: #fff;
  font-family: var(--font-secondary);
}

.working-block1-info ul {
  list-style: none;
  padding: 0;
}

.working-block1-info ul li {
  padding: 0 0 15px 26px;
  position: relative;
  font-size: 15px;
  font-weight: 500;
}
.working-block1-info ul li i {
  font-size: 16px;
  color: #56b8e6;
}
.blockchainImg {
  width: 450px;
}
@media (max-width: 550px) {
  .blockchainImg {
    display: none;
    /* width: 400px; */
  }
  .working-block2 h2 {
    font-size: 18px;
  }
}
</style>
<style scoped>
@media (min-width: 920px) {
  [dir="rtl"] .card-body {
    padding: 1.5rem;
    min-height: 250px;
  }
}
</style>
